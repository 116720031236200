@font-face {
  font-family: 'proxima_nova_ththin';
  src: url('../fonts/proxima-nova/ProximaNova-Thin-webfont.eot');
  src: url('../fonts/proxima-nova/ProximaNova-Thin-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima-nova/ProximaNova-Thin-webfont.woff') format('woff'),
  url('../fonts/proxima-nova/ProximaNova-Thin-webfont.ttf') format('truetype'),
  url('../fonts/proxima-nova/ProximaNova-Thin-webfont.svg#proxima_nova_ththin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_rgregular';
  src: url('../fonts/proxima-nova/ProximaNova-Reg-webfont.eot');
  src: url('../fonts/proxima-nova/ProximaNova-Reg-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima-nova/ProximaNova-Reg-webfont.woff') format('woff'),
  url('../fonts/proxima-nova/ProximaNova-Reg-webfont.ttf') format('truetype'),
  url('../fonts/proxima-nova/ProximaNova-Reg-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_thextrabold';
  src: url('../fonts/proxima-nova/ProximaNova-Xbold-webfont.eot');
  src: url('../fonts/proxima-nova/ProximaNova-Xbold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/proxima-nova/ProximaNova-Xbold-webfont.woff') format('woff'),
  url('../fonts/proxima-nova/ProximaNova-Xbold-webfont.ttf') format('truetype'),
  url('../fonts/proxima-nova/ProximaNova-Xbold-webfont.svg#proxima_nova_thextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}
